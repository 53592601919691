<template>
    <div class="video_ad">
        <!-- 视频广告 -->
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" >
            <el-form-item label="视频" prop="youtube_video_url">
                <el-input v-model="ruleForm.youtube_video_url"  @change="f_changeVideoUrl(ruleForm.youtube_video_url)">
                    <template slot="prepend">Http://</template>
                </el-input>
            </el-form-item>
            <el-form-item label="号召语" prop="call_to_actions_text">
                <!-- <el-input v-model="ruleForm.call_to_actions_text" placeholder="请输入号召语" maxlength="999999" show-word-limit v-limitWords="15" @input="$maxLength('call_to_actions_text',15)" @blur="f_changeCallToAction(ruleForm.call_to_actions_text)"></el-input> -->
                <el-autocomplete class="inline-input" :fetch-suggestions="f_querySearch" value-key="shopNow" :value="ruleForm.call_to_actions_text" style="width:100%;" placeholder="请输入号召语" :maxlength="999999" show-word-limit v-limitWords="15" @input="f_inputCallToAction" @select="f_changeCallToAction" @change="f_inputCallToAction">
                    <template slot-scope="{ item }">
                        <div class="flex">
                            <p>{{ item.shopNow }}</p>
                            <p>{{ item.name_zhcn }}</p>
                        </div>
                    </template>
                </el-autocomplete>
                <!-- <el-select
                    v-model="ruleForm.call_to_actions"
                    filterable
                    @change="f_inputVideo"
                    allow-create
                    default-first-option
                    placeholder="请选择号召语">
                    <el-option
                    v-for="item in callToActionData"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select> -->
            </el-form-item>
            <el-form-item label="随播横幅广告" prop="bannerImg" class="wrap_item">
                 <image-uploader v-model="ruleForm.bannerImg" :isCropper='false' :accountId="accountId" @input='f_overUpload' :multi="1" :maxSize="150" :width="60" :height="60" :pixelData="{width:300,height:60}"></image-uploader>
            </el-form-item>
            <el-form-item label="显示网址" prop="breadcrumb1">
                <div class="moreIput">
                    <el-input v-model="ruleForm.name" placeholder="www.example.com" show-word-limit disabled></el-input>
                    <p>/</p>
                    <el-input v-model="ruleForm.breadcrumb1" maxlength="999999" show-word-limit  @change="f_inputVideo" v-limitWords="15" @input="$maxLength('breadcrumb1',15)"></el-input>
                    <p>/</p>
                    <el-input v-model="ruleForm.breadcrumb2" maxlength="999999" show-word-limit @change="f_inputVideo" v-limitWords="15" @input="$maxLength('breadcrumb2',15)"></el-input>
                </div>
            </el-form-item>
            <el-form-item
                v-for="(domain, index) in ruleForm.headlines"
                :label="index==0?'标题':'    '"
                :key="domain.key"
                :prop="'headlines.' + index + '.text'"
                :rules="{
                }"
            >
            <div class="more">
                <el-input v-model="domain.text" @change="f_inputVideo" maxlength="999999" show-word-limit v-limitWords="30" @input="$maxLength('text',30,index,'headlines')" :class="[isCludeArabic?'rtl':'']"></el-input>
            </div>
            </el-form-item>
            <el-form-item
                v-for="(domain, index) in ruleForm.long_headlines"
                :label="index==0?'长标题':'    '"
                :key="domain.key"
                :prop="'long_headlines.' + index + '.text'"
                :rules="{
                required: true, message: '长标题不能为空', trigger: ['change','blur']
                }"
            >
            <div class="more">
                <el-input v-model="domain.text" @change="f_inputVideo" maxlength="999999" show-word-limit v-limitWords="90" @input="$maxLength('text',90,index,'long_headlines')" :class="[isCludeArabic?'rtl':'']"></el-input>
            </div>
            </el-form-item>
            <el-form-item
                v-for="(domain, index) in ruleForm.descriptions"
                :label="index==0?'描述':'    '"
                :key="domain.key"
                :prop="'descriptions.' + index + '.text'"
                :rules="{
                required: true, message: '描述不能为空', trigger: ['change','blur']
                }"
            >
            <div class="more">
                <el-input v-model="domain.text" type="textarea" maxlength="999999" show-word-limit @change="f_inputVideo" v-limitWords="90" @input="$maxLength('text',90,index,'descriptions')" :class="[isCludeArabic?'rtl':'']"></el-input>
            </div>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import { debounce } from 'lodash'
import {mapState} from 'vuex'
import {createVideoAsset} from '@/api/google/ceateAdGg.js'
import imageUploader from '../common/imageUploader/index.vue'
export default {
    components:{imageUploader},
    props:{
        value:{
            type:Object,
            default:()=>{}
        },
        isPass:{
            type:Boolean,
            default:false
        },
        defaultCallToActions:{
            type:Object,
            default:()=>{}
        },
        accountId: {
            type: [String, Array],
            default: "",
        },
        isCludeArabic:{
            type:Boolean,
            default:false
        }
    },
    data(){
        return {
          callToActionData:[],
          ruleForm:{},
          rules:{
              call_to_actions_text:[
                    { required: true, message: '请输入号召语', trigger: ['blur','change'] }
              ],
              youtube_video_url:[
                    { required: true, message: '请输入视频链接', trigger: ['blur','change'] }
              ],
          },
        }
    },
    computed:{
        ...mapState({
            constDataGoogle: state => state.initData.constDataGoogle,
        }),
        thirdData(){
            return this.value
        },
    },
    watch:{
        isPass:{
            handler(newV){
                this.f_submit()
            },
        },
        thirdData:{
            handler(newV){
                this.f_initData(newV)
            },
            immediate:true,
            deep:true
        },
        defaultCallToActions:{
            handler(newV){
                this.f_initCallToAction(newV)
            }
        }
    },
    created(){},
    mounted(){},
    beforeDestroy(){},
    methods:{
        // 改变视频链接
        f_changeVideoUrl:debounce(async function (url){
            this.$showLoading()
            let res = await createVideoAsset({videoUrl:url,accountId:this.accountId.toString()})
            if(res.code == 0){
                this.$set(this.ruleForm,'resourceName',res.data.resourceName||'')
                this.$hideLoading()
                this.f_inputVideo()
            }
        },200),
         // 显示建议
        f_querySearch(queryString, cb) {
            var restaurants = this.constDataGoogle.defaulcallToActionData;
            console.log(restaurants,";;;")
            var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
            // 调用 callback 返回建议列表的数据
            cb(results);
        },
        createFilter(queryString) {
            return (restaurant) => {
                return restaurant.ShopNow?(restaurant.ShopNow.toLowerCase().indexOf(queryString.toLowerCase()) === 0):[];
            };
        },
        // 输入框
        f_inputCallToAction(val){
            console.log(val,"val-----")
            this.ruleForm.call_to_actions_text = val
            this.f_common()
        },
        // 行动号召力
       f_changeCallToAction(item){
            this.ruleForm.call_to_actions_text = item.shopNow
            this.f_common()
        },
        f_common(){
            this.$maxLength('call_to_actions_text',15)
            this.f_inputVideo()
        },
        // 初始化行动号召力
        f_initCallToAction(newV){
            this.$nextTick(()=>{
                this.$set(this.ruleForm,'call_to_actions_text',newV.shopNow)
            })
        },
        // 校验是否通过
        async f_submit(){
            // await this.$nextTick()
            this.$refs.ruleForm.clearValidate();
            this.$refs.ruleForm.validate((valid) => {
                if (!valid) {
                    this.$store.commit('initData/setGoogleValid',false)
                    this.$firstInputFocus()
                    return false;
                }
            });
        },
        // 上传成功的值
        f_overUpload(val,name){
            console.log(val,"上传成功了",this.ruleForm.bannerImg);
            this.$set(this.ruleForm,'bannerImgName',name)
            this.f_inputVideo()
        },
        // 改变值
        f_inputVideo(){
            // 改变视频的链接
            let data = this.ruleForm
            console.log(data,"这是视频广告的行动号召力---开始",this.ruleForm)
            let videos = [{asset:data.resourceName,youtube_video_url:data.youtube_video_url}]
            // 改变行动号召力
            let array = data.call_to_actions_text,call_to_actions = []
            let obj = {text:data.call_to_actions_text}
            call_to_actions.push(obj)
            let companion_banners = []
            // 改变随播横幅广告
            if(this.ruleForm.bannerImg){
                let obj = {asset:this.ruleForm.bannerImgName,image_url:this.ruleForm.bannerImg}
                companion_banners.push(obj)
            }
            console.log(array,"这是视频广告的行动号召力---",this.ruleForm)
            this.$emit('input',{...data,videos,call_to_actions,companion_banners})
        },
        // 初始化视频广告
        f_initData(data){
            let newData = {...data}
            // 单独处理call_to_actions
            if(newData.call_to_actions&&newData.call_to_actions.length>0){
                newData.call_to_actions_text = newData.call_to_actions[0].text
                this.$set(newData,'call_to_actions_text',newData.call_to_actions[0].text)
            }
            // 单独处理视频链接格式
            if(newData.videos&&newData.videos.length>0&&newData.videos[0].youtube_video_url){
                newData.youtube_video_url = newData.videos[0].youtube_video_url
                newData.resourceName = newData.videos[0].asset||''
            }
            // 单独处理改变随播横幅广告
            if(newData.companion_banners&&newData.companion_banners.length>0&&newData.companion_banners[0].image_url){
                newData.bannerImg = newData.companion_banners[0].image_url
            }
            this.ruleForm = {...newData}
            console.log(this.ruleForm,"-----")
        },
    },
}
</script>

<style lang="scss" scoped>
::v-deep.rtl{
    direction: rtl;
    padding-right: 30px;
}
.flex{
    display: flex;
    justify-content: space-between;
}
.video_ad{
    .moreIput{
        display: flex;
        justify-content: flex-start;
        flex-wrap: nowrap;
        p{
            margin: 0 5px;
        }
    }
}
::v-deep.wrap_item{
    margin-bottom: 0;
    .el-form-item__label{
        line-height: 20px;
    }
    .el-form-item__content{
        line-height: 20px;
    }
}
</style>