<template>
  <div class="audience_characterist">
    <!-- 性别 年龄 育儿状况 家庭收入 -->
    <div
      v-if="allData.length > 0"
      class="adgroup_criterias"
    >
      <div
        class="box"
        v-for="(p, index) in allData"
        :key="p.id"
      >
        <div class="title">
          {{
            p[0].type == 'GENDER'
              ? '性别'
              : p[0].type == 'AGE_RANGE'
              ? '年龄'
              : p[0].type == 'PARENTAL_STATUS'
              ? '育儿状况'
              : '家庭收入'
          }}
        </div>
        <el-checkbox-group
          v-model="adgroup_criterias[index + '']"
          @change="f_changeAdgroup"
          :min="1"
        >
          <el-checkbox
            v-for="item in p"
            :label="item.id"
            :key="item.id"
            >{{ item.nameZhcn }}</el-checkbox
          >
        </el-checkbox-group>
      </div>
    </div>
  </div>
</template>

<script>
const audienceData = require('@/api/dataJson/audience.json');
export default {
  components: {},
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      audienceData: audienceData,
      adgroup_criterias: { 0: [], 1: [], 2: [], 3: [] }, //绑定选择的数据
    };
  },
  computed: {
    // 数据汇总  四类数据重组为二位数据
    allData() {
      let data = this.audienceData,
        genderData = [],
        ageData = [],
        parentingData = [],
        incomeData = [];
      genderData = data.filter((item) => item.type == 'GENDER');
      ageData = data.filter((item) => item.type == 'AGE_RANGE'); //年龄
      parentingData = data.filter((item) => item.type == 'PARENTAL_STATUS'); //育儿状况
      incomeData = data.filter((item) => item.type == 'INCOME_RANGE'); //家庭收入
      return [genderData, ageData, parentingData, incomeData];
    },
    thirdData() {
      return this.value;
    },
  },
  watch: {
    thirdData: {
      handler(newV) {
        this.f_initData(newV.filter((item) => item && item.type != 'USER_INTEREST'));
      },
      immediate: true,
      deep: true,
    },
  },
  created() {},
  mounted() {},
  beforeDestroy() {},
  methods: {
    f_initData(data) {
      console.log(data);
      if (data.length == 0) {
        this.adgroup_criterias['0'] = this.allData[0].map((item) => item.id);
        this.adgroup_criterias['1'] = this.allData[1].map((item) => item.id);
        this.adgroup_criterias['2'] = this.allData[2].map((item) => item.id);
        this.adgroup_criterias['3'] = this.allData[3].map((item) => item.id);
        this.f_changeAdgroup();
      } else {
        this.adgroup_criterias['0'] = data.filter((item) => item.type == 'GENDER').map((item) => item.criterion_id);
        this.adgroup_criterias['1'] = data.filter((item) => item.type == 'AGE_RANGE').map((item) => item.criterion_id);
        this.adgroup_criterias['2'] = data
          .filter((item) => item.type == 'PARENTAL_STATUS')
          .map((item) => item.criterion_id);
        this.adgroup_criterias['3'] = data
          .filter((item) => item.type == 'INCOME_RANGE')
          .map((item) => item.criterion_id);
      }
      console.log(data, '初始化受众特征', this.adgroup_criterias);
    },
    // 改变受众
    f_changeAdgroup() {
      let array = this.value.filter((item) => item && item.type == 'USER_INTEREST');
      let data = this.adgroup_criterias;
      let genderData = [],
        ageData = [],
        parentingData = [],
        incomeData = [];
      genderData = data['0'].map((item) => {
        return { criterion_id: item, type: 'GENDER' };
      });
      ageData = data['1'].map((item) => {
        return { criterion_id: item, type: 'AGE_RANGE' };
      });
      parentingData = data['2'].map((item) => {
        return { criterion_id: item, type: 'PARENTAL_STATUS' };
      });
      incomeData = data['3'].map((item) => {
        return { criterion_id: item, type: 'INCOME_RANGE' };
      });
      console.log([...genderData, ...ageData, ...parentingData, ...incomeData]);
      this.$emit('input', [...genderData, ...ageData, ...parentingData, ...incomeData, ...array]);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables.scss';
.audience_characterist {
  padding: 10px 0;
  @mixin flex {
    display: flex;
    justify-content: flex-start;
    flex-wrap: nowrap;
  }
  .adgroup_criterias {
    border: 1px solid $bgColorDark;
    border-radius: 5px;
    margin: 0 20px;
    @include flex;
    .box {
      border-right: 1px solid $bgColorDark;
      margin: 0 auto;
      width: 25%;
      .title {
        margin: 10px 0px 10px 20px;
        color: #333;
      }
      ::v-deep.el-checkbox-group {
        @include flex;
        flex-direction: column;
        margin-left: 20px;
        .el-checkbox {
          height: 35px;
          line-height: 35px;
        }
      }
      &:last-child {
        border-right: none;
      }
    }
  }
}
</style>
