<template>
<div class="materials_all">
    <div class="tips">已选中 <span class="color-danger"> {{ checkList.length+uploadList.length}} </span> 个</div>
    <my-tabs
      :tabsList="tabsList"
      :value="tabValue"
      @tabsEmit="f_tabsEmit"
    ></my-tabs>
    <!-- 素材库 -->
    <div class="choose_materials" v-loading="loading" v-show="tabValue == 0">
        <div class="all_checked">
            <el-checkbox v-model="allChecked" @change="f_allCheckedChange" v-if="materialsData.length">全选</el-checkbox>
        </div>
        <el-checkbox-group v-model="checkList" @change="f_checklist" :min="0" :max="limitNum - uploadList.length" v-infinite-scroll="f_getImageLists" :infinite-scroll-disabled="noMore">
            <div class="box" v-for="(item,index) in materialsData" :key="index">
                <el-checkbox :label="item.id" :disabled="f_disabledMaterials(item)"></el-checkbox>
                <img :src="item.image_asset.full_size.url" alt="" @click="f_check(item.id,item)" >
                <p :style="{width:item.image_asset.full_size.width_pixels*(112/item.image_asset.full_size.height_pixels)+'px'}" :title="item.name" @click="f_check(item.id,item)">{{item.name}}</p>
                <p>像素：{{item.image_asset.full_size.width_pixels}}*{{item.image_asset.full_size.height_pixels}}</p>
            </div>
        </el-checkbox-group>
        <p v-if="noMore" class="nomore">没有更多了~~~</p>
    </div>
    <!-- 本地上传 -->
    <div v-show="tabValue == 1">
        <file-uploader @uploadData="f_uploadData" :dataFileNum="dataFileSize" :limitSize="limitSize" :accountId="accountId" acceptType=".gif,.jpg,.jpeg,.png"></file-uploader>
    </div>
    <footer>
        <el-row>
            <el-button  size="medium" @click="f_cancel">取 消</el-button>
            <el-button type="primary" size="medium" @click="f_save">保 存</el-button>
        </el-row>
    </footer>
</div>
</template>

<script>
import {accountImageAssets} from '@/api/google/ceateAdGg.js'
import myDrawer from '../common/drawer/index.vue'
import myTabs from '../common/myTabs/index'
import paginations from "../common/paginations/index";
import fileUploader from '../common/fileUploader/index.vue'
export default {
    components:{myDrawer,fileUploader,paginations,myTabs},
    props:{
        limitSize: {//宽高比为限定的三种尺寸
            type: Array,
            default: function() {
                return [];
            }
        },
        typeMaterials: {//素材还是徽标
            type: String,
            default: ''
        },
        limitNum: {//限制数量
            type: Number,
            default: 0
        },
        accountId: {
            type: [String, Array],
            default: "",
        },
        checkIds: {//选中的素材
            type: Array,
            default: function() {
                return [];
            }
        },
        limit: {//选中的限制
            type: Array,
            default: function() {
                return [];
            }
        },
    },
    data(){
        return {
            tabValue: '0',
            tabsList: [
                { name: '素材库', id: '0' },
                { name: '本地上传', id: '1' },
            ],
            noMore:false,
            //分页信息
            paginations: {
                    page: 0, // 当前页
                    size: 20, // 1页显示多少条
                    total: 0 // 总数
            },
            loading:false,
            myDrawerObjUpload:{
                title:'本地上传',
                drawer:false,
                size:'60%'
            },
			checkList:[],//选中的文件
            materialsData:[],//素材列表
            allChecked:false,
            uploadList:[],//本地上传的素材
        }
    },
    computed:{
        dataFileSize(){
            return this.limitNum - this.checkList.length
        }
    },
    watch:{},
    created(){},
    mounted(){},
    beforeDestroy(){},
    methods:{
        // 选中的上传素材
        f_uploadData(data){
            this.uploadList = [...data]
        },
        f_tabsEmit(tab) {
            this.tabValue = tab
        },
        // 给不可用素材添加disable
        f_disabledMaterials(item){
            let p = item.image_asset.full_size.width_pixels/item.image_asset.full_size.height_pixels
            return this.limit.includes(p.toFixed(2))?false:true
        },
        async f_getImageLists(){
           if(this.noMore) return
           this.noMore = true
           let ids = this.checkIds
           let params = {
               accountId:this.accountId.toString(),
               resourceNames:[],
               ps:this.paginations.size,
               p:this.paginations.page||null
           }
           this.loading = true;
           let res = await accountImageAssets(params)
           if(res.code == 0){
                this.loading = false;
                this.materialsData = [...this.materialsData,...res.data.assets]
                this.paginations.total = res.data.total
                this.paginations.page = res.data.after
                this.$nextTick(()=>{
                    if(ids&&ids.length>0) {
                        let array = []
                        this.materialsData.map(item=>{
                            if(ids.includes(item.image_asset.full_size.url)) array.push(item.id)
                        })
                        this.checkList = [...array]
                    }
                })
                if(res.data.assets.length<this.paginations.size) this.noMore = true
                else this.noMore = false
           }
        },
        // 打开本地上传
        f_upload(){
            this.myDrawerObjUpload.drawer = true
        },
        f_save(){
			if(this.checkList.length==0&&this.uploadList.length==0) return this.$message.warning('请选择素材！')
            if(this.checkList.length+this.uploadList.length>this.limitNum) return this.$message.warning(`素材不能超过${this.limitNum}个!`)
            let array = this.materialsData.filter(item=>this.checkList.includes(item.id))
            if(this.uploadList.length) array = array.concat(this.uploadList)
            this.$emit('close',array,this.typeMaterials)
        },
        f_cancel(){
            this.$emit('close')
        },
        // 全选
        f_allCheckedChange(v){
            let data = this.materialsData.filter(item=>{
                let p = item.image_asset.full_size.width_pixels/item.image_asset.full_size.height_pixels
                return this.limit.includes(p.toFixed(2))
            })
            if(v){
                this.checkList = data.map(v=>v.id).slice(0,this.limitNum-this.uploadList.length)
            }else{
                this.checkList = [];
            }
        },
        // 多选
        f_checklist(){
            console.log(this.checkList)
        },
        f_check(id,a) {
            if(this.f_disabledMaterials(a)) return
            
            if(this.checkList.includes(id)){
                let num = -1
                this.checkList.map((item,k)=>{
                    if(item===id) num = k
                })
                if(num>-1) this.checkList.splice(num,1)
            }else {
                if((this.checkList.length+this.uploadList.length)>=(this.limitNum)) return 
                this.checkList.push(id)
            }
        },
    },
}
</script>

<style lang="scss" scoped>
@import "~@/styles/google/materials.scss";
$uploadHeight:112px;
.materials_all{
    position: relative;
    margin-top: 15px;
    .tips{
        position: absolute;
        top: 10px;
        right: 30px;
        z-index: 10;
        color: #333;
        font-size: 14px;
    }
}
.choose_materials{
    overflow: hidden;
    padding-bottom: 50px;
    .all_checked{
		padding: 20px;
	}
    p.nomore{
        text-align: center;
        color: #999;
    }
    ::v-deep.el-checkbox__input.is-disabled .el-checkbox__inner{
        background-color: #d3d4d7;
    }
    footer{
        width: 80%;
        height: 50px;
        background-color: #fff;
        margin-left: 20px;
        right: 0;
        padding-right: 70px;
        padding-bottom: 10px;
        bottom: 0;
        z-index: 2;
    }
    .boxC{
        font-size: 32px;
        width: $uploadHeight;
        text-align: center;
        height: $uploadHeight;
        line-height: $uploadHeight;
        border: 1px dashed #66AEFE;
        margin: 10px;
        margin-left: 20px;
        border-radius: 5px;
        position: relative;
        background-color: #E6F2FF;
        color:$titleColor;
        cursor: pointer;
        .txt{
            font-size: 12px;
            line-height: 12px;
            position: absolute;
            margin-left: 30px;
            margin-top: -30px;
        }
        .addIcon{
            i{
                position: relative;
                top: -5px;
            }
        }
    }
}
</style>